<template>
  <div>
    <style-guide-asset-card
      :src-asset="asset"
      :title="asset.name || '[ Missing Filename ]'"
    >
      <template #cardAdditionalContent>
        <div
          v-if="isBasketRemoveLoading || isAddingToBasketLoading || isOperationLoading"
          class="flex justify-center w-full h-4"
        >
          <div class="w-full text-xs flex justify-center mb-2">
            <base-svg
              class="h-3 w-3 mr-2 text-primary-red inline-block"
              src="icons/circleSpinner.svg"
              tag="div"
            />
            <div class="text-primary-gray">
              {{ isBasketRemoveLoading ? 'Removing ...' : '' }}
              {{ isAddingToBasketLoading ? 'Adding ...' : '' }}
              {{ isOperationLoading ? 'Please wait ...' : '' }}
            </div>
          </div>
        </div>
        <div
          v-else-if="canDownload"
          class="flex justify-center w-full"
        >
          <base-svg
            :title="'File Size: ' + printFileSize(asset.fileSize)"
            class="text-primary-red cursor-pointer hover:text-custom-gray-1 mr-3 w-4 h-4"
            src="icons/download.svg"
            :class="{ 'mr-3': assetType === 'pdf' }"
            :svg-attributes="{
              stroke: 'currentColor',
              title: 'File Size: ' + printFileSize(asset.fileSize),
            }"
            @click.stop="downloadAsset(asset)"
          />

          <base-svg
            v-if="!alreadyInBasket"
            class="text-primary-red cursor-pointer hover:text-custom-gray-1 mr-3 w-4 h-4"
            :class="{ 'mr-3': assetType === 'pdf'}"
            src="icons/basket.svg"
            @click.stop="handleBasketItem(asset.addedToBasket)"
          />
          <base-svg
            v-else
            class="text-primary-red cursor-pointer hover:text-custom-gray-1 mr-3 w-4 h-4"
            :class="{ 'mr-3': assetType === 'pdf'}"
            src="icons/cross.svg"
            @click.stop="confirmDeleteBasketItem(asset)"
          />

          <base-svg
            v-if="assetType === 'pdf'"
            :title="'Open in New Tab (File Size: ' + printFileSize(asset.fileSize)"
            class="cursor-pointer text-primary-red hover:text-custom-gray-1 w-3.5 h-3.5"
            src="icons/expand.svg"
            @click.stop="openPdf()"
          />
        </div>
      </template>
    </style-guide-asset-card>

    <base-modal
      v-model="showDeleteBasketModal"
      modal-title="Confirm Delete Basket Item"
      @hidden="resetDeleteBasketModal()"
    >
      <template #modalBody>
        <div class="px-4 py-3">
          <div class="font-sm text-center mb-2">
            Are you sure to delete <strong>{{ assetToBeDeleted.name }}</strong> from basket ?
          </div>
          <div class="text-center mt-2">
            <base-button
              class="mr-3"
              variant="btn-primary"
              text="Yes"
              :is-loading="isBasketRemoveLoading"
              @click="deleteBasketItem()"
            />
            <base-button
              variant="btn-link"
              text="No"
              @click="resetDeleteBasketModal()"
            />
          </div>
        </div>
      </template>
    </base-modal>
  </div>
</template>

<script>
import { defineAsyncComponent, computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import useBasket from '@/hooks/basket.js';
import useToastNotifications from '@/hooks/toastNotifications.js';
import {
    SUCCESS,
    WARNING,
    ERROR,
    ASSET_REMOVE_FROM_BASKET_FAILED,
    ASSET_REMOVE_FROM_BASKET_SUCCESSFULLY,
    ASSET_DOWNLOAD_FAILED,
    ASSET_DOWNLOADED_SUCCESSFULLY,
    ASSET_ADDED_TO_BASKET_SUCCESSFULLY,
    ASSET_ADDED_TO_BASKET_FAILED
} from '@/constants/alerts.js';

import { printFileSize } from '@/helpers/util';
import useStyleGuideAssets from '@/hooks/styleGuideAssets.js';

export default {
    name: 'GalleryAssetCard',

    components: {
        StyleGuideAssetCard: defineAsyncComponent(() => import('@/components/StyleGuideAssetCard.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue')),
        BaseModal: defineAsyncComponent(() => import('@/components/generic-components/BaseModal.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue'))
    },

    props: {
        asset: {
            type: Object,
            required: true
        }
    },

    setup (props) {
        const { notificationsStack } = useToastNotifications();
        const store = useStore();

        const assetType = computed(() => (props?.asset?.name?.split('.').pop()||'').toLowerCase());
        const canDownload = computed(() => props.asset.canDownload);

        const basketItems = computed(() => store.getters['basket/getBasketList']);
        const alreadyInBasket = ref(false);

        watch(
            () => basketItems.value,
            () => {
                alreadyInBasket.value = basketItems.value?.some(basket => basket.originalFileId === props.asset.originalFileId);
            },
            { deep: true, immediate: true }
        );

        // basket logic
        const { addToBasket, basketLoading } = useBasket();
        const { assetsList } = useStyleGuideAssets();
        const isAddingToBasketLoading = ref(false);
        const handleBasketItem = async (checkAddedToBasket) => {
            try {
                if (alreadyInBasket.value || checkAddedToBasket || isAddingToBasketLoading.value) {
                    return;
                }
                isAddingToBasketLoading.value = true;
                const body = {
                    ids: [props.asset.originalFileId]
                };
                await addToBasket(body);
                store.dispatch('assets/updateAssetsList', assetsList.value.data.map(asset => {
                    if (props.asset.originalFileId === asset.originalFileId) {
                        return {
                            ...asset,
                            addedToBasket: true
                        };
                    }
                }));
                setTimeout(() => {
                    notificationsStack.value.push({
                        type: SUCCESS,
                        message: ASSET_ADDED_TO_BASKET_SUCCESSFULLY
                    });
                }, 1000);
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: ASSET_ADDED_TO_BASKET_FAILED
                });
            } finally {
                isAddingToBasketLoading.value = false;
            }
        };

        // download basket item logic
        const isOperationLoading = ref(false);       

        const downloadAsset = async (asset) => {
            if (isOperationLoading.value) return;
            try {
                isOperationLoading.value = true;

                let getPresignedURL
                {
                  let url = props.asset.asgardMasterBaseUrl
                  if (!url) throw `Master Download URL is not available`

                  //const selectedLicensee = store.getters['auth/getSelectedLicensee'];
                  //const selectedLicenseeId = selectedLicensee.licenseeId;
                  console.log('JAMES: downloading from GalleryAssetCard');

                  const masterData = {
                    fileId: props.asset.styleGuideFileId,
                    objectId: props.asset.styleGuideAssetId,
                    filename: props.asset.name,
                    licenseeIds: (store.getters['auth/getSelectedLicensee']?.licenseeId || ''), // (store.getters['auth/getUserLicensees'] || []).map(l => l.licenseeId),
                    downloadType: 'Asset Gallery'
                  }
                  url += `?masterData=${encodeURIComponent(window.btoa(JSON.stringify(masterData)))}`

                  if ( (props.asset.wmFlag||'').toLowerCase().startsWith('y') ) url = `${url}&skipWatermark=true`

                  getPresignedURL = await store.dispatch('upload/getDownloadAttachmentURLViaUrl', { params: { url } })

                }

                if (!getPresignedURL || !getPresignedURL?.presignedURL) throw new Error('Download URL is not available.')
                const windowOpened = window.open(getPresignedURL?.presignedURL, '_blank');
                if (!windowOpened) {
                    notificationsStack.value.push({
                        type: ERROR,
                        message: 'A popup blocker may be preventing the application. Please add this site to your exception list in order to upload/ download.'
                    });
                } else {
                    notificationsStack.value.push({
                        type: SUCCESS,
                        message: ASSET_DOWNLOADED_SUCCESSFULLY
                    });
                }
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: `${ASSET_DOWNLOAD_FAILED}. ${err}`
                });
            } finally {
                isOperationLoading.value = false;
            }
            // initDownload(props.asset.fileDownloadUrl, props.asset.name);
        };

        // pdf logic
        const openPdf = async () => {
            try {
                isOperationLoading.value = true;

                let url = props.asset.renditions.transformations.pdf
                if ( (props.asset.wmFlag||'').toLowerCase().startsWith('y') ) url = `${url}&skipWatermark=true`

                const resp = await store.dispatch('upload/getDownloadAttachmentURLViaUrl', {
                    params: {
                        url
                    }
                })
                if (!resp?.presignedUrl) throw new Error('Download URL is not available.')
                const windowOpened = window.open(resp?.presignedUrl, '_blank');
                if (!windowOpened) {
                    notificationsStack.value.push({
                        type: ERROR,
                        message: 'A popup blocker may be preventing the application. Please add this site to your exception list in order to upload/ download.'
                    });
                } else {
                    notificationsStack.value.push({
                        type: SUCCESS,
                        message: ASSET_DOWNLOADED_SUCCESSFULLY
                    });
                }

            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: `${ASSET_DOWNLOAD_FAILED}. ${err}`
                });
            } finally {
                isOperationLoading.value = false;
            }
        };

        // confirm delete search modal logic
        const assetToBeDeleted = ref(null);
        const confirmDeleteBasketItem = (asset) => {
            assetToBeDeleted.value = asset;
            setDeleteBasketModalVisibility(true);
        };

        const showDeleteBasketModal = ref(false);
        const setDeleteBasketModalVisibility = (visibility) => {
            showDeleteBasketModal.value = visibility;
        };
        const resetDeleteBasketModal = () => {
            assetToBeDeleted.value = null;
            setDeleteBasketModalVisibility(false);
        };

        // delete basket item logic
        const isBasketRemoveLoading = ref(false);
        const { removeFromBasket, fetchBasket } = useBasket();
        const deleteBasketItem = async () => {
            try {
                isBasketRemoveLoading.value = true;
                await removeFromBasket(assetToBeDeleted.value.originalFileId);
                assetToBeDeleted.value.addedToBasket = false;
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: ASSET_REMOVE_FROM_BASKET_FAILED
                });
            } finally {
                await fetchBasket();
                notificationsStack.value.push({
                    type: SUCCESS,
                    message: ASSET_REMOVE_FROM_BASKET_SUCCESSFULLY
                });
                resetDeleteBasketModal();
                isBasketRemoveLoading.value = false;
            }
        };

        return {
            basketLoading,
            downloadAsset,
            handleBasketItem,
            openPdf,
            assetType,
            isOperationLoading,
            isAddingToBasketLoading,
            printFileSize,
            alreadyInBasket,
            basketItems,
            resetDeleteBasketModal,
            showDeleteBasketModal,
            confirmDeleteBasketItem,
            assetToBeDeleted,
            deleteBasketItem,
            isBasketRemoveLoading,
            canDownload
        };
    }
};
</script>
